<template>
  <!-- Sidebar form -->
  <div>
    <property-action-edit
      :sidebarStatus="sidebarStatus"
      :edit-item="editItem"
      @updateSidebarStatus="
        (val) => {
          sidebarStatus = val
        }
      "
      @refetch-data="refetchData"
      @resetItem="editItem = {}"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('commons.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('commons.entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchEnQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('propertyActionsTable.titleEnglish')"
              />

              <b-form-input
                v-model="searchEsQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('propertyActionsTable.titleSpanish')"
              />

              <b-form-input
                v-model="searchSwQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('propertyActionsTable.titleSwedish')"
              />

              <b-button variant="primary" @click="sidebarStatus = true">
                <span class="text-nowrap">{{ $t('buttons.create') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPropertyActionsListTable"
        class="position-relative"
        :items="fetchPropertyActions"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('commons.empty')"
        :sort-desc.sync="isSortDirDesc"
        :busy="!fetchPropertyActions"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" small />
            <strong class="ml-1">{{ $t('commons.loading') }}</strong>
          </div>
        </template>

        <!-- Column: isActive -->
        <template #cell(isActive)="data">
          <b-badge
            :variant="`light-${resolveStatusVariant(data.item.isActive)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.isActive) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row align-h="end">
            <b-col cols="12" lg="2" md="3" sm="4" xs="12">
              <b-button
                variant="outline-secondary"
                size="sm"
                :id="`edit-${data.item.id}`"
                @click="
                  sidebarStatus = true
                  editItem = data.item
                "
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-tooltip
                :target="`edit-${data.item.id}`"
                triggers="hover"
                placement="left"
              >
                <b>{{ $t('alert.edit.title') }}</b>
              </b-tooltip>
            </b-col>
            <b-col cols="12" lg="2" md="3" sm="4" xs="12">
              <b-button
                variant="outline-danger"
                size="sm"
                :id="`delete-${data.item.id}`"
                @click="
                  deleteModal = true
                  selectedItem = data.item
                "
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
              <b-tooltip
                :target="`delete-${data.item.id}`"
                triggers="hover"
                placement="left"
              >
                <b>{{ $t('alert.delete.title') }}</b>
              </b-tooltip>
            </b-col>
          </b-row>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t('footer.table.showing') }} {{ dataMeta.from }} {{ $t('footer.table.to') }} {{ dataMeta.to }} {{ $t('footer.table.of') }}
              {{ dataMeta.of }} {{ $t('footer.table.entries') }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <AlertDialog
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      :message="$t('alert.delete.message')"
      :title="`${$t('alert.delete.title')} ${selectedItem.titleEn}`"
      @action="
        deletePropertyAction(selectedItem.id)
        selectedItem = {}
        deleteModal = false
      "
      @close="deleteModal = false"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import usePropertyActionsList from './usePropertyActionsList'
import propertyActionsStoreModule from './propertyActionsStoreModule'
import AlertDialog from '@/components/AlertDialog.vue'
import PropertyActionEdit from './PropertyActionEdit.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,

    vSelect,
    AlertDialog,
    PropertyActionEdit,
  },
  data() {
    return {
      deleteModal: false,
      selectedItem: {},
    }
  },
  setup() {
    const PROPERTY_ACTIONS_APP_STORE_MODULE_NAME = 'app-property-actions'
    const sidebarStatus = ref(false)
    const editItem = ref(null)

    // Register module
    if (!store.hasModule(PROPERTY_ACTIONS_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROPERTY_ACTIONS_APP_STORE_MODULE_NAME,
        propertyActionsStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROPERTY_ACTIONS_APP_STORE_MODULE_NAME))
        store.unregisterModule(PROPERTY_ACTIONS_APP_STORE_MODULE_NAME)
    })

    const {
      fetchPropertyActions,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchEnQuery,
      searchEsQuery,
      searchSwQuery,
      sortBy,
      isSortDirDesc,
      refPropertyActionsListTable,
      refetchData,
      deletePropertyAction,
    } = usePropertyActionsList()

    return {
      // Sidebar
      sidebarStatus,
      editItem,

      fetchPropertyActions,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchEnQuery,
      searchEsQuery,
      searchSwQuery,
      sortBy,
      isSortDirDesc,
      refPropertyActionsListTable,
      refetchData,
      deletePropertyAction,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
